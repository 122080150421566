@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --color-background: rgb(248, 243, 228);
  --color-background-80: rgb(248, 243, 228, 0.8);
  --color-background-2: rgb(9, 9, 11);
  --color-foreground: rgb(51, 28, 18);
  --color-primary: rgb(255, 117, 57);
  --color-primary-lighter: rgb(255, 147, 101);
  --color-primary-darker: rgb(255, 101, 34);
  --color-secondary: rgb(248, 243, 228);

  /* New Colors */
  --color-off-white: rgb(255, 252, 244); /* #fffcf4 */
  --color-off-white-80: rgb(255, 252, 244, 0.8);
  --color-off-white-70: rgb(255, 252, 244, 0.7);
  --color-parchement: rgb(249, 244, 228); /* #f9f4e4 */
  --color-pastel-brown: rgb(231, 207, 180); /* #e7cfb4 */
  --color-plum: rgb(60, 43, 50); /* #3C2B32 */
  --color-plum-1: rgb(106, 69, 85); /* #6A4555 */
  --color-plum-2: rgb(151, 125, 136); /* #977D88 */

  --color-bright-plum: rgb(163, 66, 108); /* #a3426c */
  --color-bright-plum-50: rgba(163, 66, 108, 0.5); /* 50% opacity */
  --color-bright-plum-7: rgba(163, 66, 108, 0.07); /* 7% opacity */
  --color-bright-plum-25: rgba(163, 66, 108, 0.25); /* 25% opacity */
  --color-bright-plum-light: rgb(190, 86, 131); /* #be5683 */
  --color-bright-plum-intense: rgb(145, 47, 90); /* #912f5a */
  --color-bright-plum-3: rgb(209, 160, 181); /* #D1A0B5 */
  --color-plum-background: rgb(236, 215, 206); /* #ECD7CE */
  --color-plum-background-2: rgb(229, 206, 206); /* #E5CECE */

  --color-plum-light: rgb(106, 69, 85); /* #6A4555 */

  --color-green: rgb(79, 195, 110); /* #4fc36e */
  --color-green-15: rgba(79, 195, 110, 0.15); /* #4fc36e */
  --color-green-intense: rgb(41, 181, 78); /* #29b54e */

  --color-red: rgb(255, 23, 92); /* #FF175C */
  --color-red-light: rgb(255, 78, 131); /* #ff4e83 */
  --color-red-intense: rgb(225, 28, 86); /* #e11c56 */
  --color-red-10: rgba(255, 23, 92, 0.1); /* #ff175c25 */

  --color-slate-75: rgb(245, 247, 251); /* #f5f7fB */
  --color-slate-250: rgb(215, 223, 233); /* #d7dfe9 */
  --color-slate-350: rgb(175, 188, 205); /* #afbccd */

  --color-burgundy: rgb(35, 27, 30); /* #231B1E */
  --color-burgundy-80: rgb(35, 27, 30, 0.9); /* #231B1E */
  --color-burgundy-2: rgb(71, 57, 62); /* #47393E */
  --color-burgundy-3: rgb(112, 101, 105); /* #706569 */
  --color-burgundy-4: rgb(153, 145, 148); /* #999194 */
  --font-sourcesanspro: "Source Sans Pro", sans-serif;

  --color-bright-green-dark: rgba(196, 227, 109, 1); /* #C4E36D */
  --color-bright-green: rgba(223, 255, 133, 1); /* #DFFF85 */
  --color-bright-green-light: rgba(233, 255, 163, 1); /* #E9FFA3 */

  --color-pastel-purple-dark: rgba(222, 149, 255, 1); /* #DE95FF */
  --color-pastel-purple: rgba(232, 182, 255, 1); /* #E8B6FF */
  --color-pastel-purple-light: rgba(243, 218, 255, 1); /* #F3DAFF */

  --color-pastel-blue-dark: rgba(135, 176, 255, 1); /* #87B0FF */
  --color-pastel-blue: rgba(173, 201, 255, 1); /* #ADC9FF */
  --color-pastel-blue-light: rgba(214, 228, 255, 1); /* #D6E4FF */

  --color-pastel-orange-dark: rgba(247, 135, 72, 1); /* #F78748 */
  --color-pastel-orange: rgba(255, 161, 108, 1); /* #FFA16C */
  --color-pastel-orange-light: rgba(255, 199, 167, 1); /* #FFC7A7 */

  --color-orange-light-25: rgba(251, 191, 36, 0.25); /* #FBBF24 */

  --radius: 12px;
}

html {
  scroll-behavior: smooth;
}

@layer base {
  :root {
    @apply bg-background;
  }

  .light {
    --primary-bg: var(--color-bright-plum);
    --primary-text: var(--color-off-white);
    --primary-hover-bg: var(--color-bright-plum-light);
    --primary-hover-text: var(--color-off-white);
    --primary-active-bg: var(--color-bright-plum-intense);
    --secondary-text: var(--color-bright-plum);
    --secondary-bg: var(--color-bright-plum-7);
    --secondary-hover-bg: var(--color-bright-plum-25);
    --secondary-border: var(--color-bright-plum-50);
    --secondary-hover-border: var(--color-bright-plum-50);
    --secondary-hover-text: var(--color-bright-plum);
    --secondary-active-bg: var(--color-bright-plum-25);
    --tertiary-text: var(--color-burgundy);
    --tertiary-border: var(--color-bright-plum);
    --tertiary-hover-border: var(--color-bright-plum);
    --tertiary-hover-text: var(--color-bright-plum);
  }

  .dark {
    --primary-bg: var(--color-bright-plum);
    --primary-text: var(--color-off-white);
    --primary-hover-bg: var(--color-bright-green);
    --primary-hover-text: var(--color-burgundy);
    --secondary-text: var(--color-off-white);
    --secondary-border: var(--color-off-white);
    --secondary-hover-border: var(--color-bright-green);
    --secondary-hover-text: var(--color-bright-green);
    --tertiary-text: var(--color-burgundy);
    --tertiary-border: var(--color-bright-plum);
    --tertiary-hover-border: var(--color-bright-green);
    --tertiary-hover-text: var(--color-bright-green);
  }

  body {
    font-family: var(--font-source-sans-3);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @apply text-foreground;
  }

  p {
    @apply leading-7;
  }

  b {
    @apply font-bold;
  }

  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }

  ol {
    display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }

  ol ul {
    list-style-type: circle;
    margin-block-start: 0px;
    margin-block-end: 0px;
  }
}

@layer utilities {
  .fade-b {
    mask-image: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0) 100%);
  }
  @media (max-width: 768px) {
    [data-duration="normal"] {
      --duration: 10s !important;
    }
  }
}

.consent-studio .cookie-toggle {
  display: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 0px solid inherit;
  font-weight: normal;
  font-size: 1rem;
  -webkit-box-shadow: inherit;
  transition: background-color 5000s ease-in-out 0s;
}

@keyframes dotbounce {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(2);
  }

  50% {
    transform: scale(1);
  }
}

@keyframes showOpacity {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.videoStreamingInputBg {
  background: rgba(35, 27, 30, 0.8);
  backdrop-filter: blur(5px);
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeUp {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes pulseShadow {
  from {
    box-shadow: 0 0 1px #ff753980;
  }

  to {
    box-shadow: 0 0 10px #ff753980;
  }
}

.refresherAnimation {
  animation: spin 500ms linear infinite;
}

@keyframes wave {
  0%,
  100% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(1.5);
  }
}

.animate-wave {
  animation: wave 1s infinite ease-in-out;
}

.animate-wave-delay-1 {
  animation: wave 1s infinite ease-in-out 0.2s;
}

.animate-wave-delay-2 {
  animation: wave 1s infinite ease-in-out 0.4s;
}

.marquee {
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: 4px;
  mask-image: linear-gradient(to right, hsl(0 0% 0% / 0), hsl(0 0% 0% / 1) 20%, hsl(0 0% 0% / 1) 80%, hsl(0 0% 0% / 0));
}

.marquee__group {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 4px;
  min-width: 100%;
  animation: scroll-x 40s linear infinite;
}

.marquee__group__slower {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 4px;
  min-width: 100%;
  animation: scroll-x 120s linear infinite;
}

@keyframes scroll-x {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - 4px));
  }
}

[data-radix-popper-content-wrapper] {
  min-width: 0 !important;
  white-space: normal;
}
